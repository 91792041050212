/** @format */

.css-2b097c-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.loading-container span span {
  margin: 8px !important;
}
.carousel-indicators {
  display: none !important;
}
.carousel-item::after {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.main-container {
  width: 100%;
  height: 23vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}
.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
    0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  -webkit-transform: scale(0.84);
          transform: scale(0.84);
  border-radius: 50%;
  -webkit-animation: animateContainer 0.75s ease-out forwards 0.4s;
          animation: animateContainer 0.75s ease-out forwards 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.check-container .check-background svg {
  width: 65%;
  -webkit-transform: translateY(0.25rem);
          transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  -webkit-animation: animateCheck 0.35s forwards 1.25s ease-out;
          animation: animateCheck 0.35s forwards 1.25s ease-out;
}
.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, rgba(73, 218, 131, 1), transparent);
  -webkit-animation: animateShadow 0.75s ease-out forwards 0.75s;
          animation: animateShadow 0.75s ease-out forwards 0.75s;
}
@-webkit-keyframes animateContainer {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}
@keyframes animateContainer {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    -webkit-transform: scale(1);
            transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset,
      0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}
@-webkit-keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
.text-message {
  margin: auto;
  width: 100% !important;
  /* border: 1px solid red;s */
  text-align: center;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500 !important;
  color: #6c757d;
  word-wrap: break-word;
}
.modal-backdrop.show {
  opacity: 0.3;
}
.reset-password .ant-result {
  padding: 18px 7px !important;
}
.resend-text {
  text-decoration: underline;
  color: red;
  font-size: 15px;
  cursor: pointer;
  margin-left: 2px;
}
.ant-input-affix-wrapper-focused:focus {
  border: 0px solid white !important;
}
.bakery .ant-tabs-tab {
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px !important;
}
.bakery
  .ant-tabs-card.ant-tabs-top
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom
  > div
  > .ant-tabs-nav
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin-left: 3px !important;
}
.fab {
  background-color: transparent;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  transition: height 300ms;
  transition-timing-function: ease;
  position: absolute;
  right: 50px;
  bottom: 50px;
  text-align: center;
  overflow: hidden;
}

.fab:hover {
  height: 344px;
}

.fab:hover .mainop {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.minifab {
  position: fixed;
  right: 2rem !important;
  bottom: 1.5rem !important;
  width: 100px;
  height: 48px;
  border-radius: 2px;
  z-index: 5;
  float: left;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: white;
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.rts___tab {
  padding: 7px 20px !important;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  margin: 0 5px;
}
@keyframes loader {
  0%,
  10%,
  100% {
    width: 80px;
    height: 80px;
  }
  65% {
    width: 150px;
    height: 150px;
  }
}

@-webkit-keyframes loader {
  0%,
  10%,
  100% {
    width: 80px;
    height: 80px;
  }
  65% {
    width: 150px;
    height: 150px;
  }
}

@keyframes loaderBlock {
  0%,
  30% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  55% {
    background-color: #ed2124;
  }
  100% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

@-webkit-keyframes loaderBlock {
  0%,
  30% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  55% {
    background-color: #ed2124;
  }
  100% {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
  }
}

@keyframes loaderBlockInverse {
  0%,
  20% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  55% {
    background-color: #ed2124;
  }
  100% {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }
}

@-webkit-keyframes loaderBlockInverse {
  0%,
  20% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  55% {
    background-color: #ed2124;
  }
  100% {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
  }
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 9999;
  height: 100%;
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  -webkit-transform: translate(-50%, -50%) rotate(45deg) translate3d(0, 0, 0);
  animation: loader 1.2s infinite ease-in-out;
  -webkit-animation: loader 1.2s infinite ease-in-out;
  z-index: 9999;
}

.loader span {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #ee4040;
  animation: loaderBlock 1.2s infinite ease-in-out both;
  -webkit-animation: loaderBlock 1.2s infinite ease-in-out both;
  z-index: 9999;
}

.loader span:nth-child(1) {
  top: 0;
  left: 0;
}

.loader span:nth-child(2) {
  top: 0;
  right: 0;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.loader span:nth-child(3) {
  bottom: 0;
  left: 0;
  animation: loaderBlockInverse 1.2s infinite ease-in-out both;
  -webkit-animation: loaderBlockInverse 1.2s infinite ease-in-out both;
}

.loader span:nth-child(4) {
  bottom: 0;
  right: 0;
}
.ant-input {
  height: 44px;
  background-color: #f1f7fc; /* Light grey color */

  border-radius: 4px;
  border: none !important;

  color: #333; /* Text color */
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #f1f7fc !important;
  border-color: #ff4d4f !important;
}
.ant-select-selector {
  height: 44px !important; /* Same height as Ant Design input */
  background-color: #f1f7fc !important; /* Same background color as Ant Design input */
  border-radius: 4px !important;

  color: #333;
  border: none !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-selector .ant-select-selection-item,
.ant-select-selector .ant-select-selection-placeholder {
  margin-top: 5px;
}

.ant-input:focus {
  box-shadow: none !important;
  /* Add any other custom styles for the focused state if needed */
}
.form-control {
  background-color: #f1f7fc !important;
  border: none !important;
  border-radius: 4px !important;
  /* height: 44px !important; */
}
.profile-card .form-control {
  height: 44px !important;
}
.form-control:focus {
  background-color: #f6fafd !important;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #f1f7fc !important;
  border: 1px solid #f1f7fc !important;
  /* border-radius: 0.25rem; */
  border-radius: 4px !important;
}
.ant-select-item {
  height: 44px !important; /* Same height as Ant Design input */
  display: flex !important;
  align-items: center !important;
}
.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  /* Add any other custom styles for the focused state if needed */
}
.ant-pagination-item {
  height: 44px; /* Same height as Ant Design input */
  background-color: white; /* Same background color as Ant Design input */
  border-radius: 4px !important;
  width: 20px !important;
  min-width: 40px !important;
  border: none !important;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}
.ant-picker {
  border: none !important;
  border-radius: 4px !important;
  height: 44px; /* Same height as Ant Design input */
  background-color: #f1f7fc; /* Same background color as Ant Design input */
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #f1f7fc !important;
  border-color: #ff4d4f;
}
.ant-picker-focused {
  border: none !important;
  box-shadow: none !important;
}
.ant-picker-input input {
  border: none !important;
  box-shadow: none !important;
}
.ant-pagination-item a {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 5px !important;
}
.ant-pagination-item-link {
  height: 44px !important; /* Same height as Ant Design input */
  background-color: white !important; /* Same background color as Ant Design input */
  border-radius: 4px !important;
  margin-top: -6px !important;
  border: none !important;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
  /* border: 1px solid #01256469 !important; */
}
.ant-pagination-options-size-changer {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
}
.MuiOutlinedInput-root {
  background-color: #f1f7fb !important;
  border: none !important;
}
.ant-pagination-item-active {
  background-color: #f1f7fb !important;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px !important;
  /* font-weight: bold !important; */
  /* border: 1px solid #002059 !important; */
}
ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: none !important;
  cursor: not-allowed;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.input-group-prepend {
  height: 44px !important;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 0px !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  border-radius: 4px !important;
}
.ant-input-suffix {
  background-color: #f1f7fc !important;
  margin: 0 !important;
  padding-right: 8px !important;
}
.ant-input-affix-wrapper .ant-input::-webkit-input-placeholder {
  margin-left: 10px !important;
}
.ant-input-affix-wrapper .ant-input::placeholder {
  margin-left: 10px !important;
}
.btn {
  border-radius: 4px !important;
  /* height: 40px !important; */
}
.ant-btn {
  border-radius: 4px !important;
  /* height: 40px !important; */
}
/* th {
  padding: 0.8rem 0.8rem;
  background-color: #f1f7fc !important;
  border-bottom-width: 1px;
  font-size: 18px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
} */
.table-responsive thead {
  height: 50px !important;
}
/* .table-responsive .table td,
.table th {
  padding: 0px !important;
  vertical-align: center !important;
  border-top: 1px solid #dee2e6;
  font-size: 12px;
} */
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  /* padding: 0px !important; */
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s;
  display: inline-flex;
  border-radius: 4px !important;
}
.ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

.ant-input-affix-wrapper > .ant-input:not(textarea) {
  padding: 10px !important;
}
.ant-input-group-addon button {
  height: 44px !important;
}
.ant-select-item-option-selected {
  background-color: #1b99a52d !important;
  color: #1b9aa5 !important;
}
.bg-light {
  /* padding: 15px; */
}
.bg-light .inner-bg-light {
  background-color: white;
  /* margin: 15px; */
  padding: 15px;
}

.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: none !important;
  border-radius: 2px;
  transition: all 0.3s;
}
.card-header {
  background-color: white !important;
}
.verification-container .ant-input {
  width: 3rem !important;
  margin-bottom: 9px;
  margin-right: 5px !important;
}
