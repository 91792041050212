/** @format */

.promotion-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}

.promotion-content {
  background: #ffffff;
  border-radius: 12px;
  text-align: center;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
}

.promotion-image {
  width: 150px;
  height: auto;
  margin: 20px 0;
}

.promotion-heading {
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0;
  color: #333;
}

.promotion-message-container {
  background: linear-gradient(135deg, #f7b42c, #fc575e);
  padding: 20px;
  border-radius: 8px;
  color: #fff;
}

.promotion-text {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  line-height: 1.4;
}

.promotion-highlight {
  font-size: 32px;
  color: #ffffff;
  background: #e3201e;
  padding: 0 8px;
  border-radius: 4px;
}

.promotion-message {
  font-size: 18px;
  margin-top: 10px;
  line-height: 1.5;
}

.promotion-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  background-color: #e3201e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, color 0.3s;
}

.promotion-close:hover {
  background-color: #c8102e;
  color: #fff;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .promotion-heading {
    font-size: 24px;
  }

  .promotion-text {
    font-size: 20px;
  }

  .promotion-highlight {
    font-size: 24px;
  }

  .promotion-message {
    font-size: 16px;
  }

  .promotion-image {
    width: 120px;
  }

  .promotion-close {
    width: 35px;
    height: 35px;
    font-size: 20px;
  }
}
