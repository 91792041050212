/** @format */

.server-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.server-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.server-error-heading {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.server-error-text {
  font-size: 18px;
  margin-bottom: 40px;
  text-align: center;
}

.server-error-button {
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.server-error-button:hover {
  background-color: #2980b9;
}
