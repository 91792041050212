html[data-v-ab80551d],
body[data-v-ab80551d] {
  -webkit-font-smoothing: antialiased;
}
body[data-v-ab80551d] {
  background: none 0px 0px repeat scroll rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
}
h1[data-v-ab80551d],
h2[data-v-ab80551d],
h3[data-v-ab80551d],
h4[data-v-ab80551d],
h5[data-v-ab80551d],
h6[data-v-ab80551d] {
  font-family: Rubik, sans-serif;
  margin-bottom: 0px;
}
p[data-v-ab80551d] {
  color: rgb(0, 0, 0);
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: justify;
}
a[data-v-ab80551d] {
  color: rgb(41, 43, 44);
  text-decoration: none;
  transition: all 0.5s ease 0s;
}
a[data-v-ab80551d]:hover {
  color: rgb(255, 50, 77);
  text-decoration: none;
}
a[data-v-ab80551d]:focus {
  outline: none;
}
img[data-v-ab80551d] {
  max-width: 100%;
}
ul[data-v-ab80551d],
li[data-v-ab80551d],
ol[data-v-ab80551d] {
  margin: 0px;
  padding: 0px;
}
.w-80[data-v-ab80551d] {
  width: 83.3333%;
  margin: auto;
}

/* navbar starts here */
header .navbar-nav > li > a[data-v-ab80551d] {
  color: #232323;
  font-size: 13px;
  font-weight: 500;
  margin: 0 10px;
  padding: 5px 0 5px;
  text-transform: uppercase;
  letter-spacing: 0.015em;
}
.homelogin[data-v-ab80551d] {
  background: #eeeeee;
}
.homecart[data-v-ab80551d] {
  color: #fff;
  font-weight: 600;
  background: #00a79e;
  padding: 3px 11px;
  border-radius: 500px;
}
[class*="overlay_bg_"][data-v-ab80551d]::before {
  background-color: rgb(0, 0, 0);
  inset: 0px;
  content: "";
  position: absolute;
  z-index: 1;
}
.overlay_bg_60[data-v-ab80551d]::before {
  opacity: 0.6;
}
.heading_s1[data-v-ab80551d] {
  margin-bottom: 10px;
}
.font_style1.sub_heading[data-v-ab80551d] {
  font-size: 24px;
}
.sub_heading[data-v-ab80551d] {
  color: rgb(255, 50, 77);
  margin-bottom: 10px;
  display: block;
}
.bg_background[data-v-ab80551d] {
  background-position: center center;
  background-size: cover;
}
.p-100[data-v-ab80551d] {
  padding: 100px 0px;
}
.pt-50[data-v-ab80551d] {
  padding-top: 50px;
}
.pb-50[data-v-ab80551d] {
  padding-bottom: 50px;
}
.btn-red[data-v-ab80551d] {
  background-color: rgb(0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
}
.btn-red[data-v-ab80551d]::before {
  background-color: rgb(57, 168, 158) !important;
}
.btn-white[data-v-ab80551d] {
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
  color: rgb(51, 51, 51);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.btn-white[data-v-ab80551d]::before {
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  content: "";
  background-color: rgb(255, 255, 255);
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
}
.btn-default[data-v-ab80551d]:hover::before,
.btn-white[data-v-ab80551d]:hover::before,
.btn-dark[data-v-ab80551d]:hover::before {
  right: 0%;
  left: auto;
  width: 0px;
}
.btn-white[data-v-ab80551d]:hover {
  color: rgb(255, 255, 255) !important;
}
#footer[data-v-ab80551d] {
  background: #1c1c1c;
  padding: 0px 0px 23px;
  color: rgb(255, 255, 255);
  font-size: 14px;
}
#footer .footer-newsletter[data-v-ab80551d] {
  padding: 50px 0px;
  background: rgb(64, 64, 64);
}
#footer .footer-newsletter h4[data-v-ab80551d] {
  font-size: 24px;
  margin: 0px 0px 20px;
  padding: 0px;
  line-height: 1;
  font-weight: 600;
}
#footer .footer-newsletter form[data-v-ab80551d] {
  margin-top: 30px;
  background: rgb(255, 255, 255);
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="email"][data-v-ab80551d] {
  border: 0px;
  padding: 8px;
  width: calc(100% - 140px);
}
#footer .footer-newsletter form input[type="submit"][data-v-ab80551d] {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border: 0px;
  font-size: 16px;
  padding: 0px 30px;
  margin: 3px;
  background: rgb(233, 107, 86);
  color: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  border-radius: 50px;
}
#footer .footer-newsletter form input[type="submit"][data-v-ab80551d]:hover {
  background: rgb(230, 87, 63);
}
#footer .footer-top[data-v-ab80551d] {
  background: rgb(28 28 28);
  border-top: 1px solid rgb(71, 71, 71);
  border-bottom: 1px solid rgb(71, 71, 71);
  padding: 30px 0px 1px;
}
#footer .footer-top .footer-info[data-v-ab80551d] {
  margin-bottom: 30px;
}
#footer .footer-top .footer-info h3[data-v-ab80551d] {
  font-size: 18px;
  margin: 0px 0px 20px;
  padding: 2px 0px;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p[data-v-ab80551d] {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0px;
  font-family: Raleway, sans-serif;
  color: rgb(255, 255, 255);
}
#footer .footer-top .social-links a[data-v-ab80551d] {
  font-size: 18px;
  display: inline-block;
  background: rgb(84, 84, 84);
  color: rgb(255, 255, 255);
  line-height: 1;
  padding: 8px 0px;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: all 0.3s ease 0s;
}
#footer .footer-top .social-links a[data-v-ab80551d]:hover {
  background: rgb(233, 107, 86);
  color: rgb(255, 255, 255);
  text-decoration: none;
}
#footer .footer-top h4[data-v-ab80551d] {
  font-size: 16px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links[data-v-ab80551d] {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul[data-v-ab80551d] {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
#footer .footer-top .footer-links ul i[data-v-ab80551d] {
  padding-right: 2px;
  color: rgb(236, 127, 109);
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li[data-v-ab80551d] {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li[data-v-ab80551d]:first-child {
  padding-top: 0px;
}
#footer .footer-top .footer-links ul a[data-v-ab80551d] {
  color: rgb(255, 255, 255);
  transition: all 0.3s ease 0s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a[data-v-ab80551d]:hover {
  color: rgb(233, 107, 86);
}
#footer .footer-top .footer-contact[data-v-ab80551d] {
  margin-bottom: 5px;
}
#footer .footer-top .footer-contact p[data-v-ab80551d] {
  line-height: 26px;
  color: #fff;
  text-align: start;
}
.footer-contact img[data-v-ab80551d] {
  width: 178px;
}
#footer .copyright[data-v-ab80551d] {
  text-align: center;
  padding-top: 30px;
}
#footer .credits[data-v-ab80551d] {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: rgb(255, 255, 255);
}
.credits a[data-v-ab80551d] {
  color: aqua;
}
@media (max-width: 767px) {
.poslist li[data-v-ab80551d] {
    padding-left: 0;
}
.w-80[data-v-ab80551d] {
    width: 100%;
}
.heading_s1 h2[data-v-ab80551d] {
    font-size: 20px;
    margin-bottom: 10px;
}
}
a.navbar-brand img[data-v-ab80551d] {
  height: 57px;
}
.carousel-caption.banner-content[data-v-ab80551d] {
  z-index: 9;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recentstores img[data-v-ab80551d] {
  height: 9em;
  object-fit: contain;
  width: 9em;
  /* background: transparent; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
ul[data-v-ab80551d] {
  list-style: none;
}
.heading_s1[data-v-ab80551d] {
  margin-bottom: 28px !important;
  margin: auto;
}
.text-red[data-v-ab80551d] {
  color: rgb(255, 50, 77);
}
.recentstores p[data-v-ab80551d] {
  text-align: center;
}
.recentstores[data-v-ab80551d] {
  text-align: center;
}

/* CTA Section */
.cta-section[data-v-ab80551d] {
  text-align: center;
  padding: 110px 0 100px;
  background-size: cover;
}
.cta-section h5[data-v-ab80551d] {
  position: relative;
  color: #fff;
  font-size: 14px;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.cta-section h2[data-v-ab80551d] {
  position: relative;
  color: #fff;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 50px;
}
.cta-section .link-box a[data-v-ab80551d] {
  margin: 0 10px 10px;
}
.btn-style-one[data-v-ab80551d] {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 16px 30px;
  padding-right: 55px;
  font-weight: 600;
  border-radius: 3px;
  overflow: hidden;
  text-transform: capitalize;
  vertical-align: middle;
  background: #00a79e;
}
.btn-style-one.style-two[data-v-ab80551d] {
  background-color: transparent;
  border: 2px solid #fff;
  padding: 14px 30px;
  padding-right: 55px;
}
.auto-container[data-v-ab80551d] {
  font-weight: 600;
  font-size: 30px;
  max-width: 850px;
  margin: auto;
}
.ser_img img[data-v-ab80551d] {
  width: 100px;
}
.mytemplates img[data-v-ab80551d] {
  width: 75px;
  /* border: 1px solid #eee; */
  margin-bottom: 8px;
}
.p-15[data-v-ab80551d] {
  padding: 15px;
}
.alt-font[data-v-ab80551d] {
  font-size: 14px;
}
.carousel-caption[data-v-ab80551d] {
  z-index: 40 !important;
}
.restaurant-template .carousel-indicators[data-v-ab80551d] {
  display: none !important;
}
.carousel-indicators[data-v-ab80551d] {
  display: none !important;
}
